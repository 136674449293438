import * as React from 'react';
import Layout from '../components/Layout';
import OpinionsFromUsers from '../components/OpinionsFromUsers';
import Header from '../components/Header';
import IframeMeeting from '../components/IframeMeeting';
import Footer from '../components/Footer';
import Carousel from '../components/Carousel';
import LanguageSwitcher from '../components/LanguageSwitcher';
import Divider from '../components/Divider';
import DynamicBoxes from '../components/DynamicBoxes';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';

export default function MeetingPage() {
  const { t } = useTranslation();

  const featuresData = [
    {
      gridSize: 6,
      title: t("MeetingPage00"),
      description: t(
        "MeetingPage01"
      ),
    },
    {
      gridSize: 6,
      title: t("MeetingPage02"),
      description: t(
        "MeetingPage03"
      ),
    },
    {
      gridSize: 12,
      title: t("MeetingPage04"),
      description: t(
        "MeetingPage05"
      ),
    },
    {
      gridSize: 12,
      title: t("MeetingPage06"),
      description: t(
        "MeetingPage07"
      ),
    },
  ];

  return (
    <Layout>
      <Header />
      <Box sx={{ marginTop: { xs: 10, sm: 10 } }} />
      <DynamicBoxes
        mainTitleOrange={t("MeetingPage08")}
        // mainTitleWhite={t("sobre iCoachBasketball para tu club")}
        features={featuresData}
      />
      <Divider marginTop={{ xs: 5, sm: 5 }} marginBottom={0} />
      <IframeMeeting />
      <Divider marginTop={{ xs: 10, sm: 15 }} marginBottom={0} />
      <Carousel />
      <Divider marginTop={{ xs: 0, sm: 2 }} marginBottom={{ xs: 0, sm: 15 }} />
      <OpinionsFromUsers />
      <Footer />
      <LanguageSwitcher />
    </Layout>
  );
}
