import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Box, Typography } from '@mui/material';


export default function IframeMeeting() {
  const { t } = useTranslation();

  return (
    <Grid sx={{ width: '95%', maxWidth: '1000px', margin: 'auto' }}>

      <Box>
        <Typography
          sx={{
            fontFamily: 'var(--font-inter), sans-serif',
            padding: '10px',
            fontSize: { xs: '25px', md: '40px' },
            fontWeight: 600,
            color: '#FF7720',
            backgroundSize: '200% 200%',
            WebkitBackgroundClip: 'text',
            lineHeight: 1.1,
            marginTop: '35px',
          }}
        >
          {t("BannerScheduleMeeting00")}
        </Typography>

        <Typography
          sx={{
            fontFamily: 'var(--font-inter), sans-serif',
            fontSize: { xs: '16px', md: '20px' },
            color: 'white',
          }}
        >
          {t("IframeHubspotMeeting01")}
        </Typography>
      </Box>
      <Box mt={7}>
        <iframe
          src="https://meetings-eu1.hubspot.com/gabriel-roman-bennassar?embed=true"
          style={{ width: '100%', height: '700px', border: '0', borderRadius: 10 }}
          title={t('IframeHubspotMeeting02')} // Título descriptivo para el iframe
        ></iframe>
      </Box>
    </Grid>
  );
}