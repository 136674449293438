import React from 'react';
import { Box, Card, CardActionArea, CardContent, Typography, Grid } from '@mui/material';

export default function DynamicBoxes({
  mainTitleOrange,
  mainTitleWhite,
  features = [],
}) {
  return (
    <Box sx={{ p: 4, maxWidth: '850px', margin: 'auto' }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography
            sx={{
              fontFamily: 'var(--font-inter), sans-serif',
              padding: '10px',
              fontSize: { xs: '25px', md: '40px' },
              fontWeight: 600,
              color: 'transparent',
              background: 'white',
              backgroundSize: '200% 200%',
              WebkitBackgroundClip: 'text',
              lineHeight: 1.1,
              margin: 'auto',
              marginBottom: 5,
            }}
          >
            <span style={{ color: '#FF7720' }}>{mainTitleOrange}</span>
            <br />
            <span style={{ color: 'white' }}>{mainTitleWhite}</span>
          </Typography>
        </Grid>

        {features.map((feature, index) => (
          <Grid item xs={12} md={feature.gridSize || 6} key={index} sx={{ display: 'flex' }}>
            <Card
              sx={{
                backgroundColor: 'grey.900',
                color: 'white',
                borderRadius: 3,
                width: '100%',
                height: '100%',
              }}
            >
              <CardActionArea onClick={() => { /* función onClick, e.g. navegación */ }}>
                <CardContent
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <Box
                    onClick={() => {
                      if (feature.link) {
                        window.location.href = feature.link;
                      }
                    }}
                    sx={{ width: '85%', textAlign: 'left', padding: 2 }}
                  >
                    <Typography sx={{ fontSize: '20px', fontWeight: 'bold', color: 'white' }}>
                      {feature.title}
                    </Typography>
                    <Typography variant="body2" sx={{ color: '#adafc4' }}>
                      {feature.description}
                    </Typography>
                  </Box>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}